@media (max-width: $medium-breakpoint) {
  .navBar li {
    visibility: hidden;
  }

  .activeLink {
    // background-color: rgb(221, 221, 221);
    color: rgb(0, 0, 0) !important;
  }

  .appointments {
    min-width: 1200px;
  }

  .clients {
    min-width: 1200px;
  }

  .logo-and-title {
    left: 0;
  }

  .navOption {
    justify-content: flex-start;

    a {
      padding: 0;
      text-align: left;
    }

    .buttonLink {
      padding: 0;
      text-align: left;
    }
  }
}

@media (min-width: $medium-breakpoint) {
  .bm-menu-wrap {
    visibility: hidden;
  }

  .bm-burger-button {
    pointer-events: none;
  }
}
