body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 242, 244);
  margin: 15px;
}

.header {
  position: fixed;
  height: 50px;
  line-height: 100%;
  width: 100%;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  padding: 0;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
}

.navOption {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navOption a {
  color: rgb(255, 255, 255);
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
}

.navOption a:hover {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
}

.navOption .buttonLink {
  color: rgb(255, 255, 255);
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
  background: none !important;
  border: none;
  font: inherit;
  cursor: pointer;
}

.navOption .buttonLink:hover {
  background-color: rgb(221, 221, 221) !important;
  color: rgb(0, 0, 0);
}

.activeLink {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0) !important;
}

.main {
  position: absolute;
  top: 80px;
  right: 15px;
  bottom: 0;
  left: 15px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: scroll;
}

.login {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login section {
  align-self: center;
  border-radius: 2px;
  border-top: 5px solid rgb(50, 190, 166);
  background-color: rgb(237, 237, 237);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 34px;
  width: 25%;
  min-width: 300px;
  padding: 60px 18px;
}

.login section form {
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}

.login section form input {
  font-size: 22px;
  color: #000;
  padding: 18px 22px;
  font-size: 16px;
  margin-bottom: 17px;
  border: 0;
  display: block;
  width: 100%;
}

.errorLabel {
  color: red;
  font-size: 13px;
  text-align: center;
}

.appointments {
  display: flex;
  flex: 1;
}

.appointment {
  display: flex;
  flex: 70%;
  align-items: stretch;
  align-self: stretch;
  padding-bottom: 20px;
}

.calendar {
  display: flex;
  flex: 15%;
}

.column {
  display: flex;
  flex: 50%;
}

.content {
  display: flex;
  overflow: auto;
  flex: 1;
}

.box {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: min-content;
  padding-right: 20px;
  padding-left: 10px;
}

.institution-select {
  width: 100%;
  height: 34px;
  border: 0px;
  overflow: auto;
  background: rgb(50, 190, 166);
}

.institution-select select {
  background: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 16px;
  height: 34px;
  cursor: pointer;
}

.todayButton {
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.todayButton:hover {
  background-color: rgb(221, 221, 221) !important;
  color: rgb(0, 0, 0);
}

.absences {
  margin-top: 10px;
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.absences-buttons {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.absences-button {
  flex: 1;
  align-self: center;
}

.absences-button:hover {
  background-color: rgb(221, 221, 221) !important;
  color: rgb(0, 0, 0);
}

.absences-alert {
  font-size: 12.5px;
  text-align: center;
  color: indianred;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  border: 1px solid rgb(50, 190, 166);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.currentDate {
  font-size: 14px;
  text-align: center;
  color: rgb(50, 190, 166);
  margin-top: 10px;
  padding: 5px;
  border-top: 1px solid rgb(50, 190, 166);
}

.noAppointments {
  font-size: 13px;
  text-align: center;
  color: indianred;
}

.appointmentsInLine {
  font-size: 13px;
  text-align: center;
  color: indianred;
}

.toggleAppointments {
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-top: 5px;
}

.toggleAppointments:hover {
  background-color: rgb(221, 221, 221) !important;
  color: rgb(0, 0, 0);
}

.appointmentsList {
  list-style: none;
  padding: 0;
}

.appointmentsList .selected {
  color: white;
  background-color: rgb(190, 89, 50);
}

.appointment-item {
  font-size: 11px;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  cursor: pointer;
}

.appointment-item:hover {
  background-color: darkgray;
}

.appointment-item .topRow {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.statusInLine {
  color: rgb(29, 184, 69);
}

.statusCompleted {
  color: rgb(29, 184, 69);
}

.statusCancelled {
  color: red;
}

.statusPending {
  color: rgb(238, 152, 38);
}

.appointment-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.appointment-buttons button {
  flex: 1;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.itemLabel {
  font-size: 12px;
  color: darkgray;
}

.itemValue {
  font-size: 14px;
  background-color: transparent;
  border: none;
  width: 100%;
}

hr {
  height: 1px;
  color: lightgray;
  background-color: lightgray;
  border: none;
}

.appointment-divider {
  margin-top: 20px;
  height: 2px;
  color: rgb(50, 190, 166);
  background-color: rgb(50, 190, 166);
  border: none;
  width: 100%;
}

.appointmentLabel {
  font-size: 14px;
  font-weight: bold;
}

.appointmentValue {
  font-size: 12.5px;
  color: grey;
  margin-bottom: 10px;
}

.appointment-item-date {
  font-size: 13.5px;
  color: black;
}

.appointment-item-description {
  font-size: 12px;
  color: black;
}

.appointment-item-notes {
  font-size: 12px;
  color: grey;
}

.notes-form {
  width: 100%;
}

.note-text-area {
  margin-top: 5px;
  border-radius: 2px;
  border-top: 3px solid rgb(50, 190, 166);
  background-color: rgb(237, 237, 237);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  width: 99%;
  height: 100px;
}

.create-button {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-top: 5px;
  border: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
}

.create-button:hover {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
}

.notesList {
  list-style: none;
  padding: 0;
}

.note-item {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.note-value {
  font-size: 12.5px;
  color: grey;
  margin-bottom: 10px;
}

.note-remove {
  font-size: 12px;
  text-align: center;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 0;
  padding: 10px;
  align-self: flex-end;
}

.thumbnail {
  position: relative;
  display: inline;
}

.thumbnail img {
  width: 30%;
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;
  margin: 5px;
}

.thumbnail .btn {
  position: absolute;
  bottom: 60%;
  right: 7%;
  background-color: gray;
  color: white;
  font-size: 10px;
  padding: 4px 4px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.thumbnail .btn:hover {
  background-color: black;
  color: white;
}

.upload-button {
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
}

.history {
  padding-bottom: 15px;
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.historyLabel {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.history-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.history-buttons label {
  flex: 1;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.history-buttons button {
  flex: 1;
  padding: 5px;
  background-color: rgb(50, 190, 166);
  color: rgb(255, 255, 255);
}

.history-buttons button:disabled {
  background-color: gray;
  color: rgb(255, 255, 255);
}

.clients {
  display: flex;
  flex: 1;
}

.clients-sidebar {
  display: flex;
  flex-direction: column;
  flex: 20%;
}

.clients-list {
  list-style: none;
  padding: 0;
}

.clients-list .selected {
  color: black;
  background-color: rgb(190, 89, 50) !important;
}

.client {
  padding-bottom: 15px;
}

.client-filter-form {
  display: flex;
  width: 100%;
}

.client-filter {
  font-size: 13px;
  margin-top: 5px;
  padding: 10px;
  background-color: white;
  flex: 1 !important;
}

.client-item {
  font-size: 13px;
  margin-top: 5px;
  background-color: white;
  cursor: pointer;

  label {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
  }
}

.client-item:hover {
  background-color: darkgray;
}

.client-details {
  .buttons-row {
    button {
      flex: 1;
      padding: 5px;
      background-color: rgb(50, 190, 166);
      color: rgb(255, 255, 255);
      margin-right: 20px;

      &.cancel {
        background-color: darkgray;
      }
    }
  }
}

label {
  cursor: pointer;
}

.spacer {
  height: 30px;
}

.client-link {
  color: rgb(255, 255, 255);
  background-color: rgb(50, 190, 166);
  padding: 12px 20px;
  text-decoration: none;
  text-align: center;
}

.client-link:hover {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
}

.appointment-add {
  background-color: white;
  cursor: pointer;
  text-align: center;
  border-top: 3px solid rgb(50, 190, 166);
  padding-bottom: 10px;
}

.appointment-add-title {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  padding: 20px;
}

.appointment-add-body {
  padding: 10px;
}

.appointment-add-feedback {
  margin-top: 10px;
  font-size: 12px;
  color: indianred;
}

.new-client-main {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
}

.new-client-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.new-client-item-label {
  font-size: 12px;
  color: darkgray;
  margin-top: 10px;
}

.new-client-item-input {
  font-size: 14px;
  width: 100%;
  height: 25px;
  padding: 1px 5px;
}

.new-client-item-input input {
  width: 100%;
}

.new-client-error-label {
  color: red;
  font-size: 13px;
  text-align: left;
}

.logo-and-title {
  position: absolute;
  display: flex;
  height: 100%;
  left: 20px;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
}

.title {
  font-size: 15pt;
  width: auto;
}

.print {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin: 30px;
  margin-top: 250px;
  white-space: pre-line;
  background-color: white;
}

.print-line-item {
  padding: 10px;
}

.print-button {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: white;
  background-color: rgb(50, 190, 166);
  cursor: pointer;
  margin-bottom: 15px;
  border: 0;
  width: 100%;
  font-size: 14px;
}

.print-button:hover {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
}

.tests {
  margin-top: 10px;
}

.history-disclaimer {
  font-size: 12.5px;
  color: grey;
  margin-bottom: 10px;
  text-align: justify;
}
